/*
 * @Description: 模拟数据
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 申文慧
 * @LastEditTime: 2024-05-27 14:17:30
 */

export const protectedRoute = [
  // 模拟数据
  {
    path: '/main/analog',
    name: 'Analog',
    component: () => import('$analog/views/Index'),
    meta: {
      name: '填报数据'
    },
    children: [
      {
        path: '/main/analog/basicProjectInformation',
        name: 'BasicProjectInformation',
        component: () =>
          import('$analog/views/basicProjectInformation/BasicProjectInformation.vue'),
        children: [],
        meta: {
          name: '项目基本信息',
          isAuth: true,
          authCode: 'web_base_BIMange_basicProjectInformation'
        }
      },
      // 原模拟数据配置
      {
        path: '/main/analog/analogShallowBI',
        name: 'AnalogShallowBI',
        component: () => import('$analog/views/analogShallowBI/AnalogShallowBI.vue'),
        children: [],
        meta: {
          name: '业务BI填报数据配置',
          isAuth: true,
          authCode: 'web_base_BIMange_analog'
        }
      },
      {
        path: 'analogIotBI',
        name: 'AnalogIotBI',
        component: () => import('$analog/views/analogIotBI/Index.vue'),
        children: [],
        meta: {
          name: '物联BI填报数据仅供参考',
          isAuth: true,
          authCode: 'web_base_BIMange_iot'
        }
      },
      {
        path: '/main/analog/analogShallowBI/labor/workerAttendanceDynamicsImport',
        name: 'WorkerAttendanceDynamicsImport',
        component: () =>
          import('$analog/components/analogShallowBI/labor/WorkerAttendanceDynamicsImport.vue'),
        children: [],
        meta: {
          name: '导入',
          isAuth: false,
          authCode: 'web_base_BIMange_analog'
        }
      },
      {
        path: 'gxljBI',
        name: 'GxljBI',
        component: () => import('$analog/views/gxljBI/Index.vue'),
        children: [],
        meta: {
          name: 'BI填报数据配置',
          isAuth: false,
          authCode: 'web_base_BIMange_gxljBI'
        }
      },
      // 地图管理start=========
      {
        path: '/main/analog/mapManage/MapGIS',
        name: 'ProLocation',
        component: () => import('$analog/views/mapManage/mapGIS/MapGIS.vue'),
        meta: {
          name: 'GIS地图管理',
          isAuth: true,
          authCode: 'web_base_BIMange_mapManage_mapGIS'
        }
      },
      {
        path: '/main/analog/mapManage/proLocation',
        name: 'ProLocation',
        component: () => import('$analog/views/mapManage/proLocation/ProLocation.vue'),
        meta: {
          name: '工程部位管理',
          isAuth: true,
          authCode: 'web_base_BIMange_mapManage_proLocation'
        }
      },
      {
        path: '/main/analog/mapManage/DeviceLocation',
        name: 'ProLocation',
        component: () => import('$analog/views/mapManage/deviceLocation/DeviceLocation.vue'),
        meta: {
          name: '设备位置',
          isAuth: true,
          authCode: 'web_base_BIMange_mapManage_deviceLocation'
        }
      },
      // 地图管理  end=========
      //进度填报 start====
      {
        path: '/main/analog/progressReporting',
        name: 'ProgressReporting',
        component: () => import('$analog/views/progressReporting/Index.vue'),
        meta: {
          name: '进度填报',
          isAuth: true,
          authCode: 'web_base_BIMange_progressReporting'
        }
      },
      //进度填报 end====
      // bi看板配置-界面配置
      {
        path: '/settingBI/menuSetting',
        name: 'MenuSetting',
        meta: {
          name: '菜单设置',
          isAuth: true,
          authCode: 'web_base_biSetting_menu',
          id: ''
        },
        component: () => import('$analog/views/settingBI/MenuSetting.vue')
      }
    ]
  }
]
