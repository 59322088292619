<!--
 * @Description:
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2021-08-04 15:30:51
 * @LastEditors: shawn-web lishuaistart@163.com
 * @LastEditTime: 2024-11-22 14:54:08
-->
<comment>
  # 组件注释
  切换组织 -- 组织树带搜索
</comment>
<template>
  <div id="OrgTree" class="p-6" style="position: relative; overflow: hidden">
    <a-auto-complete
      v-model="params.orgName"
      style="width: 100%"
      class="m-b-16"
      placeholder="按组织名称搜索"
      @search="search"
      @select="searchSelected">
      <template slot="dataSource">
        <a-select-option v-for="res in result" v-show="result.length" :key="res.id">{{
          res.name
        }}</a-select-option>
      </template>
    </a-auto-complete>
    <div style="min-height: 20vh; min-width: 350px; max-height: 50vh; overflow: auto">
      <a-skeleton active :loading="loading" :paragraph="{ rows: 3 }">
        <a-tree
          show-icon
          loading="switchLoading"
          :tree-data="treeData"
          :expanded-keys="expandKeys"
          @expand="onExpand"
          @select="treeSelected">
          <template slot="custom" slot-scope="record">
            <icon-font
              :type="
                record.dataRef.type == 100
                  ? 'spm-icon-bumen'
                  : record.dataRef.type == 4
                    ? 'spm-icon-xiangmubu'
                    : 'spm-icon-zuzhi'
              " />
          </template>
        </a-tree>
        <div
          v-if="switchLoading"
          class="fill-width fill-height d-flex align-center justify-center"
          style="position: absolute; top: 0; background: rgba(255, 255, 255, 0.8)">
          <a-spin spinning="switchLoading" tip="组织切换中..."> </a-spin>
        </div>
      </a-skeleton>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce'
import Cookie from 'js-cookie'
import { mapState } from 'vuex'
const isStandard = process.env.VUE_APP_VERSION == 'standard' // 是否是标板
export default {
  name: 'OrgTree',
  components: {},
  inject: ['reload'],
  props: {},
  data() {
    return {
      Util,
      api: {
        getOrgTreeApi: '/web/platform-org/contextOrg/list', // 获取组织树数据
        getNewOrgTreeApi: '/app/address-book/project-list',
        getResourceApi: '/web/role-appInfo/list', // 获取资源
        // getOrgListApi: Util.orgServer + "/web/org/search", // 获取组织下拉列表数据
        switchOrgApi: '/web/passport/user/switch-org' // 切换组织
      },
      result: [], // 搜索结果
      value: '',
      expandKeys: [],
      checkedKeys: [],
      treeData: [],
      idsTeam: [],
      loading: true,
      switchLoading: false,
      params: {
        orgName: ''
      },
      pid: 0
    }
  },
  computed: {
    ...mapState({
      curr_org: state => state.curr_org,
      tenantId: state => state.curr_tenant,
      userType: state => state.login_msg.userType,
      resources: state => state.resources,
      route: state => state.route,
      menu_mode: state => state.menu_mode
    })
  },
  created() {
    if (this.userType == 2) {
      this.getNewOrgTree()
    } else {
      this.getOrgTree()
    }
    this.$eventBus.$on('getOrgTree', () => {
      this.getOrgTree()
    })
  },
  mounted() {},
  watch: {
    curr_org: {
      handler(val) {
        this.checks = val
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onExpand(expandedKeys) {
      this.expandKeys = expandedKeys
    },
    search: debounce(function () {
      this.handleSearch()
    }, 300),
    handleSearch() {
      if (this.userType == 2) {
        this.result = this.result.filter(item => {
          return item.projectName.indexOf(this.params.orgName) > -1
        })
      } else {
        let params = {
          tenantId: this.tenantId,
          pId: this.pid ? this.pid : 0,
          orgName: this.params.orgName
        }
        this.$get(this.api.getOrgTreeApi, params)
          .then(response => {
            Util.processRes(response, () => {
              if (response.data.length > 0) {
                this.result = response.data
              } else {
                this.result = []
              }
            })
            Util.processError(this, response)
          })
          .catch(res => {
            console.warn(res)
          })
      }
    },
    searchSelected(e) {
      if (e !== 'noData') {
        this.treeSelected([e])
      }
    },
    /**
     * 切换组织项目
     */
    treeSelected(e) {
      if (e.length) {
        this.switchLoading = true
        this.$get(this.api.switchOrgApi, {
          orgId: e[0]
        })
          .then(response => {
            Util.processRes(response, () => {
              let curr = Util.getDataByProps(this.treeData, 'id', e[0])
              this.$store.commit('SET_CURR_ORG', {
                ...curr,
                type: curr.orgType
              })

              Cookie.set('jwt', response.data.tokenVo.accessToken, {
                expires: 7
              })
              Cookie.set('simpleToken', response.data.tokenVo.simpleToken, {
                expires: 7
              })
              this.$store.commit('SET_LOGINMSG', response.data.userMemberVo)
              // 参建单位信息引入
              if (response.data.userMemberVo.userType === 2) {
                this.$store.commit('SET_CONTRACTOR_INFO', response.data.contractorInfo)
              }
              this.$store.commit('SET_LOGO', response.data.tenantInfoVo.logoPic || '')
              this.$store.commit('SET_CURR_TENANT', response.data.userMemberVo.tenantId)
              this.$store.commit('SET_CURR_TENANT_CODE', response.data.userMemberVo.personNo)
              // 获取相关资源
              this.getResource(
                response.data.userMemberVo.tenantId,
                response.data.userMemberVo.id,
                response.data.userMemberVo.orgType
              )

              this.$root.eventHub.$emit('orgChange')
            })
            if (response.statusCode !== 200) {
              this.switchLoading = false
            }
            Util.processError(this, response)
          })
          .catch(res => {
            this.switchLoading = false
            console.warn(res)
          })
      }
    },
    /**
     * 获取资源
     * */
    getResource(id, roleId, type) {
      this.$get(this.api.getResourceApi, {
        tenantId: id,
        userRoleId: roleId,
        // appProperty: type,
        clientType: 'web',
        scope: 1
      })
        .then(response => {
          if (response.statusCode === 200) {
            // 存在资源时
            if (response.data && response.data.length) {
              this.$store.commit(
                'SET_RESOURCES',
                this.addCollectionProps(Util.resetTree(response.data))
              )
              // 储存原始数据，用于切换组织时获取
              localStorage.setItem(
                'resources',
                JSON.stringify(this.addCollectionProps(response.data))
              )

              // 菜单模式 -2
              if (this.menu_mode == 2) {
                // 控制菜单层级权限
                this.$emit('change', false)
                this.reload()
                return
              }
              // 控制菜单层级权限
              this.setResource({ orgType: type })
              this.$emit('change', false)
              this.reload()
            } else {
              // 菜单模式 -2
              if (this.menu_mode == 2) {
                // 控制菜单层级权限
                this.$store.commit('SET_RESOURCES', [])
                localStorage.setItem('resources', JSON.stringify([]))
                this.$router.push('/main')
                this.$emit('change', false)
                this.reload()
                return
              }
              // 没有资源的情况下
              this.$store.commit('SET_RESOURCES', [])
              localStorage.setItem('resources', JSON.stringify([]))
              this.$router.push('/main/welcome')
              this.$emit('change', false)
              this.reload()
            }
          } else {
            Util.processError(this, response)
          }
        })
        .catch(err => {
          this.$message.error(err.statusMessage)
        })
    },
    /**
     * 切换层级后进行页面权限判断
     */
    judgeAuth() {
      if (!Util.isPageAuth(this.$route.meta)) {
        this.$router.push('/403')
      }
    },
    /**
     * 收藏按钮增加属性
     * @param arr
     * @returns {Array}
     */
    addCollectionProps(arr) {
      let resources = []
      let _bi = {
        web_pm_bi_pm: '/pm-bi/#/main?token=',
        web_pm_bi_monitor: '/monitor-bi/#/main?token=',
        web_pm_bi_IotComm: '/command-bi/#/main?token=',
        web_pm_bi_infra: '/bi-v5/#/main?token='
      }
      let origin = window.location.origin
      let host = window.location.host
      let httpOriginWeb = process.env.VUE_APP_ORIGIN_HTTP_WEB
      arr.length &&
        arr.forEach(item => {
          if (item.type == 'menu') {
            item['isCollected'] = false
          }
          if (item.uri && item.uri.includes('?token=') && !this.getQueryString(item.uri, 'token')) {
            item.uri += Cookie.get('simpleToken')
          }
          // 大屏访问优化，（经拉通，私有化环境后续不用在运维端单独处理访问前缀）
          if (item.uri && Object.keys(_bi).includes(item.appCode)) {
            // 存在host私有化配置则按域名访问，不存在即为项管家/本地环境
            if (
              Object.keys(hostConfig).includes(host) &&
              process.env.VUE_APP_CURRENTMODE == 'prod'
            ) {
              item.uri = `${window.location.origin}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
            } else {
              item.uri = `${httpOriginWeb}${_bi[item.appCode]}${Cookie.get('simpleToken')}`
              if (process.env.VUE_APP_CURRENTMODE == 'test' && item.uri.includes('/command-bi')) {
                item.uri = `https://tapp.autobuild.com.cn${_bi[item.appCode]}${Cookie.get(
                  'simpleToken'
                )}`
              }

              if (
                process.env.VUE_APP_CURRENTMODE == 'prod' &&
                (item.uri.includes('/command-bi') || item.uri.includes('/monitor-bi'))
              ) {
                item.uri = `https://wxx.1357.cn${_bi[item.appCode]}${Cookie.get('simpleToken')}`
              }
            }
          }
          if (item.children && item.children.length) {
            item.children = this.addCollectionProps(item.children)
          }
          resources.push(item)
        })
      return resources
    },
    setResource(curr) {
      // 切换层级时判断层级权限过滤数据
      let originalResources = JSON.parse(localStorage.getItem('resources')) // 原始菜单数据
      let resources = this.resetTreeData(
        Util.resetTree(originalResources), // 去掉菜单中的查看
        curr.orgType
      )

      let currentData = Util.getTreeChild(
        decodeURIComponent(this.$route.fullPath),
        resources,
        'uri'
      )
      if (!currentData || !currentData.length) {
        if (this.$route.path !== '/main/welcome') this.$router.replace({ path: '/main/welcome' })
      }

      // 储存原始数据，用于切换组织时获取
      localStorage.setItem('resources', JSON.stringify(resources))
      this.$store.commit('SET_RESOURCES', resources)
    },
    // 如需修改 请同步修改 LoginStandard.vue中的方法
    resetTreeData(treeData, orgType) {
      let setTree = list => {
        list &&
          list.length &&
          list.forEach((item, i) => {
            // item.loading = true
            if (item.children && item.children.length) {
              setTree(item.children)
            }
            // if (item.children && !item.children.length && !item.uri) {
            //   list.splice(i, 1)
            //   setTree(list)
            // }
            if (item.children && !item.children.length) {
              delete item.children
            }
            // item.orgType  1公司级 2分公司级 3项目级
            if (!item.orgTypeList.includes(1)) {
              //  公司级
              let type = ['1']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(2)) {
              // 分公司级
              let type = ['2', '3', '4', '5']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
            if (!item.orgTypeList.includes(3)) {
              // 项目级
              let type = ['6']
              if (type.includes(String(orgType))) {
                list.splice(i, 1)
                setTree(list)
              }
            }
          })
      }
      setTree(treeData)
      return treeData
    },
    getOrgTree() {
      let params = {
        tenantId: this.tenantId,
        pId: this.pid ? this.pid : 0,
        orgName: this.params.orgName
      }
      this.$get(this.api.getOrgTreeApi, params)
        .then(response => {
          Util.processRes(response, () => {
            this.loading = false
            if (response.data && response.data.length) {
              this.treeData = Util.dealData(response.data, '', {
                // 被替换的字段：要替换的字段
                title: 'shortName'
              })
              this.checkedKeys = [this.curr_org.id]
              this.expandKeys = [Util.getIds(this.treeData)[0]]
              // this.idsTeam = Util.getIds(this.treeData)
            } else {
              this.treeData = []
              // this.$store.commit("SET_CURR_ORG", {});
            }
          })
          Util.processError(this, response)
        })
        .catch(res => {
          this.loading = false
          console.warn(res)
        })
    },
    getNewOrgTree() {
      this.$post(this.api.getNewOrgTreeApi)
        .then(response => {
          Util.processRes(response, () => {
            this.loading = false
            if (response.data && response.data.length) {
              this.treeData = Util.dealData(response.data, '', {
                title: 'shortName'
              })
              this.checkedKeys = [this.curr_org.id]
              this.expandKeys = [Util.getIds(this.treeData)[0]]
              // this.idsTeam = Util.getIds(this.treeData)
            } else {
              // this.$store.commit("SET_CURR_ORG", {});
            }
          })
          Util.processError(this, response)
        })
        .catch(res => {
          this.loading = false
          console.warn(res)
        })
    },
    getQueryString(url, name) {
      var reg = new RegExp('(^|\\?)' + name + '=([^&]*)(&|$)', 'i')
      var r = url.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    }
  },
  destroyed() {}
}
</script>

<style lang="less" scoped>
// .ant-tree-switcher-close{
// 	background: url('自定义关闭背景图') no-repeat;
// 	i{
// 		display: none;
// 	}
// }
// .ant-tree-switcher-open{
// 	background: url('自定义打开背景图') no-repeat;
// 	i{
// 		display: none;
// 	}
// }
</style>
