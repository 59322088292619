/*
 * @Descripttion: 封装axios请求
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-07-07 13:47:47
 * @LastEditors: 李帅
 * @LastEditTime: 2023-08-28 15:51:53
 */
import axios from 'axios'
import { Message, Modal } from 'ant-design-vue'
import Cookie from 'js-cookie'
import vuex from '../../vuex'
import router from '../../router'
let isOne = true
/*国际化*/
// import i18n from '$public/i18n/i18n';
import BaseUrlConfig from './baseUrlConfig'
const BASE_URL = BaseUrlConfig.baseUrl
let isFormData = v => {
  return Object.prototype.toString.call(v) === '[object FormData]'
}
let CancelToken = axios.CancelToken
let cancel
let cancelToken = new CancelToken(function executor(c) {
  cancel = c
})
axios.defaults.timeout = 300000
// axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
let sessions = {}

//http request 拦截器
axios.interceptors.request.use(
  config => {
    if (!isFormData(config.data)) {
      config.data = JSON.stringify(config.data)
    }
    const jwt = Cookie.get('jwt')
    const sessions = Cookie.get('sessions') ? JSON.parse(Cookie.get('sessions')) : {}
    // 判断是否是登录请求
    // true
    if (
      config.url.indexOf('admin-user/login') > -1 ||
      config.url.indexOf('restapi.amap.com') > -1 ||
      config.url.indexOf('/portal/user/getToken') > -1
    ) {
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    } else if (config.url.indexOf(':20091') > -1 || config.url.indexOf('aliyuncs.com') > -1) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      }
    } else {
      // false
      // 判断是否有登录token
      if (jwt) {
        if (isFormData(config.data)) {
          config.headers = {
            'Content-Type': 'multipart/form-data',
            token: jwt,
            // 'Authorization': jwt,
            clientId: 1001,
            ...sessions
          }
        } else {
          config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            // 'Authorization': jwt,
            token: jwt,
            clientId: 1001,
            ...sessions
          }
        }
      } else {
        if (isFormData(config.data)) {
          config.headers = {
            'Content-Type': 'multipart/form-data',
            clientId: 1001,
            ...sessions
          }
        } else {
          config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            clientId: 1001,
            ...sessions
          }
        }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
//http response 拦截器
axios.interceptors.response.use(
  response => {
    // console.log('success',response)
    response.headers.Authorization &&
      Cookie.set('jwt', 'Bearer ' + response.headers.Authorization, {
        expires: 7
      })
    if (response.data.statusCode === 401) {
      if (isOne) {
        isOne = false
        requestCancel()
        Modal.error({
          title: '退出登录',
          id: 1,
          content: response.data.statusMessage,
          okText: '确认',
          onOk() {
            let loginPlatform = localStorage.getItem('loginPlatform')
            if(loginPlatform == 'lujian') {
              _lujian_logout()
            }else{
              _logout()
            }
          },
          onCancel() {}
        })
      }
      return
    }
    return response
  },
  err => {
    console.log('fail', err.response)
    console.log('fail', err)
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误'
          break

        case 401:
          err.message = '未授权，请登录'
          break

        case 403:
          err.message = '拒绝访问'
          break

        case 404:
          err.message = `请求地址出错: ${err.response.config.url}`
          break

        case 408:
          err.message = '请求超时'
          break

        case 500:
          err.message = '服务器内部错误'
          break

        case 501:
          err.message = '服务未实现'
          break

        case 502:
          err.message = '网关错误'
          break

        case 503:
          err.message = '服务不可用'

          break

        case 504:
          err.message = '网关超时'
          break

        case 505:
          err.message = 'HTTP版本不受支持'
          break
        default:
      }
    } else {
      if (err.message !== 'cancel request') {
        err.message = '网络出现问题，请稍后再试'
      }
    }
    err.message && err.message !== 'cancel request' && Message.error(err.message)
    return Promise.reject(err)
  }
)
/**
 * @Description: 取消请求
 * @Param:
 * @Return:
 */
export function requestCancel() {
  cancel('cancel request')
  setTimeout(() => {
    cancelToken = new CancelToken(function executor(c) {
      cancel = c
    }, 5000)
  })
}

/**
 * get请求
 * @param url
 * @param params
 * @returns {Promise}
 */

export function get(url, params = {}, timeout = 300000) {
  return new Promise((resolve, reject) => {
    axios
      .get(url.indexOf('http') > -1 ? url : BASE_URL + url, {
        params: params,
        cancelToken,
        timeout
      })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, params = {}, timeout = 300000) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      params,
      data,
      timeout,
      cancelToken
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * post请求 params
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postParams(url, params = {}, data = {}, timeout = 300000) {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      params,
      data,
      timeout,
      cancelToken
    })
      .then(response => {
        console.log(response)
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 文件上传 uploadFile(使用post请求)
 * @param url
 * @param data
 * @returns {Promise}
 * @param 不需要传passportId
 *
 *$$$$$$参数module取值参考如下$$$$$$$$$

 *运营中心服务【租户、秘钥】  【20010】
 'spm-operation'

 *统一组织机构服务[组织、部门等] 【20020】
 'spm-org-structure'

 *权限管理服务[资源菜单、角色、用户等] 【20030】
 'spm-admin'

 *账号信息服务[统一账号、账户管理] 【20040】
 'spm-passport'

 *统一鉴权中心服务[Token管理、认证、刷新] 【20050】
 'spm-auth-server'

 *应用中心服务[应用注册] 【20060】
 'spm-app-center'

 *第三方服务【提供短信、消息推送】 【20070】
 'spm-third-party'

 *系统服务（基础数据、版本、服务等） 【20080】
 'spm-system'

 *文件服务[文件/图片上传下载/处理等功能] 【20090】
 'spm-file-upload'

 *即时通讯信息服务[im管理] 【20100】
 'spm-im'

 *日志监控服务logstash  【20110】
 'spm-logstash'
 */
export function uploadFile(
  url = '/labour-mgmt-service/labour/file/upload',
  data = {},
  uploadProgress,
  cancelToken,
  timeout = 3000000
) {
  return new Promise((resolve, reject) => {
    let percent = 0
    let formData = new FormData()
    if (isFormData(data)) {
      formData = data
    } else {
      //参数
      let params = data
      //创建formData 并赋值
      for (let key in params) {
        if (key == 'file') {
          // 处理无file name情况
          let d = new Date()
          let time = d.getTime()
          let _suffix = params[key].type.split('/')[1]
          let _name = params[key].name ? params[key].name : `${time}.${_suffix}`
          formData.append(key, params[key], _name)
        } else {
          formData.append(key, params[key])
        }
      }
    }
    //请求后台
    axios({
      method: 'post',
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      data: formData,
      timeout,
      cancelToken,
      onUploadProgress: progressEvent => {
        if (progressEvent.lengthComputable) {
          let complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
          percent = complete
          if (complete >= 100) {
            percent = 100
          }
          uploadProgress && uploadProgress(percent)
        }
      }
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * postFD  post formData请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function postFD(url, data = {}, timeout = 300000) {
  return new Promise((resolve, reject) => {
    let _formData = new FormData()
    let isFormData = v => {
      return Object.prototype.toString.call(v) === '[object FormData]'
    }
    if (isFormData(data)) {
      _formData = data
    } else {
      //参数
      let _params = data
      //创建formData 并赋值
      for (let key in _params) {
        _formData.append(key, _params[key])
      }
    }

    axios({
      method: 'post',
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      data: _formData,
      timeout
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function uploadImg(data = {}, timeout = 3000000) {
  return new Promise((resolve, reject) => {
    //请求后台
    axios({
      method: 'post',
      url: Util.baseUrlUpload + '/file/upload',
      data,
      timeout,
      cancelToken
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 文件保存
 * @param url
 * @param data
 * @param params
 * @param method
 * @returns {Promise<any>}
 */
export function exportFile(url, params = {}, data = {}, method = 'get', timeout = 3000000) {
  return new Promise((resolve, reject) => {
    axios({
      method: method,
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      params,
      data,
      timeout,
      cancelToken,
      responseType: 'blob' // blob arraybuffer
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 文件 上传form
 * @param url
 * @param data
 * @param params
 * @param method
 * @returns {Promise<any>}
 */
export function exportFileFD(url, params = {}, data = {}, method = 'get', timeout = 3000000) {
  return new Promise((resolve, reject) => {
    //参数
    let _params = data
    //创建formData 并赋值
    let _formData = new FormData()
    for (let key in _params) {
      _formData.append(key, _params[key])
    }
    axios({
      method: method,
      url: url.indexOf('http') > -1 ? url : BASE_URL + url,
      params,
      timeout,
      cancelToken,
      data: _formData,
      responseType: 'blob' // blob arraybuffer
    })
      .then(response => {
        // 请求成功
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

/**
 * 并发请求
 * @param requestLists
 * @returns {Promise<any>}
 */
export function getAll(requestLists = []) {
  let counter = 1 // 请求次数
  let maxRequestTimes = 5 // 最大请求次数，因为有可能别个页面就是访问不了，请求多少次也没用- -
  let result = [] // 最后的结果
  let failedList = []
  return new Promise((resolve, reject) => {
    axios
      .all(requestLists)
      .then(response => {
        result = result.concat(response.filter(i => i)) // filter返回true的时候保留该数组项，因为getDataById的catch里没有给返回值（这里也不需要），这里的resolve里就会有undefined，需要过滤掉
        let failedLength = failedList.length
        if (failedLength > 0 && counter < maxRequestTimes) {
          // 如果失败列表里有请求，并且请求次数不超过设定的值，就进行下一次请求，并且打出log
          //console.log(`第${counter}次请求完成，其中成功${requestLists.length - failedLength}个，失败${failedLength}个，正在进行第${++counter}次请求...`)
          getAll(failedList)
          failedList = [] // 这里要清空failedList，不然会一直调用。不用担心，下一次请求失败的会在getAll填充到failedList里。
        } else {
          // 表示所有请求都成功了，或者达到了最大请求次数。到这里就可以对result做进一步处理了。
          //console.log(`请求完成，共请求${counter}次, 其中成功${requestLists.length - failedLength}个，失败${failedLength}个\n`, result)
          counter = 1
          resolve(response)
        }
      })
      .catch(err => {
        reject(err)
      })
  })
}

function _logout() {
  post(Util.baseAuth2 + '/web/passport/user/logout')
    .then(response => {
      Util.processRes(response, () => {
        // 原登出逻辑
        Cookie.remove('jwt')
        localStorage.removeItem('resources')
        vuex.commit('USER_SIGNOUT')
        // location.reload()
        // window.location.href = window.location.origin + '/#/login'
        router.push({ name: 'Login' })
        isOne = true
        // this.$root.eventHub.$emit('loginOut')
      })
      Util.processError(this, response)
    })
    .catch(err => {})
}
function _lujian_logout() {
  Cookie.remove('jwt')
  localStorage.removeItem('resources')
  vuex.commit('USER_SIGNOUT')
  isOne = true
  window.location.href = 'https://ljoa.gxljjt.com/sso/login?appid=anquanxitong&service=http://api.1357.cn/hz-platform/api/web/third-platform/lujian-auth'
}
