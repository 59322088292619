/*
 * @Description: 质量
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 王召强
 * @LastEditTime: 2023-08-18 11:10:52
 */
import Settings from './settings/index'
import inspections from './inspection'
import technicalReport from './technicalReport/index'
import qualityReport from './qualityReport/index'
import qualityAcceptance from './qualityAcceptance/index'
import dataStatistics from './dataStatistics/index'
import dataStatisticsReport from './dataStatisticsReport/index'
import businessAffairsManage from './businessAffairsManage/index'
import financialManage from './financialManage/index'
import createExcellence from './createExcellence/index'
import rewardAndPunish from './rewardAndPunish/index'
import inspect from './inspect/index'
import qualityTemplate from './qualityTemplate/Index'
import engineeringApplication from './engineeringApplication/Index'
import hideProImage from './hideProImage/index.js'

export const protectedRoute = [
  {
    path: '/main/quality',
    name: 'Quality',
    component: () => import(/*/* : "质量页面入口" */ '$quality/views/Index'),
    children: [
      ...Settings,
      ...inspections,
      ...technicalReport,
      ...qualityReport,
      ...qualityAcceptance,
      ...dataStatistics,
      ...businessAffairsManage,
      ...financialManage,
      ...dataStatisticsReport,
      ...createExcellence,
      ...rewardAndPunish,
      ...inspect,
      ...qualityTemplate,
      ...engineeringApplication,
      ...hideProImage
    ]
  }
]
