/*
 * @Description:
 * @Version: 1.0.0
 * @Author: 杜凡
 * @Date: 2025-01-02 10:09:39
 * @LastEditors: 杜凡
 * @LastEditTime: 2025-01-02 23:20:09
 */
const hideProImage = [
  {
    path: '/quality/hideProImage/hideProImageLedger',
    name: 'hideProImageLedger',
    meta: {
      name: '工程影像台账',
      authCode: 'web_pm_quality_hideProImage_ledger'
    },
    component: () =>
      import('$quality/views/hideProImage/hideProImageLedger/HideProImageLedger.vue'),
    children: []
  }
]
export default hideProImage
