/*
 * @Description: aiot
 * @Version: 1.0.0
 * @Author: 李晨光
 * @Date: 2020-11-02 11:23:34
 * @LastEditors: 李晨光
 * @LastEditTime: 2024-12-04 14:46:38
 */

const laborRoute = [
  //分包单位管理
  {
    path: '/aiot/tunnel/person/unit',
    name: 'TunnelSubcontractorsManage',
    component: () => import('#/aiot/views/tunnel/person/unit/UnitsManage.vue'),
    meta: {
      name: '分包单位管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tunnel_person_unit'
    }
  },
  //班组管理
  {
    path: '/aiot/tunnel/person/team',
    name: 'TunnelGroupManage',
    component: () => import('#/aiot/views/tunnel/person/group/GroupManage.vue'),
    meta: {
      name: '班组管理',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tunnel_person_team'
    },
    children: []
  },
  {
    path: '/aiot/tunnel/person/roster',
    name: 'TunnelLaborRoster',
    component: () => import('$aiot/views/tunnel/person/roster/Roster.vue'),
    meta: {
      name: '人员花名册',
      keepAlive: false,
      isAuth: true,
      authCode: 'web_pm_tunnel_person_roster'
    }
  },
  {
    path: '/aiot/tunnel/person/workType',
    name: 'TunnelWorker',
    component: () => import('$aiot/views/tunnel/person/workType/WorkType.vue'),
    meta: {
      name: '工种维护',
      authCode: 'web_pm_tunnel_person_workType',
      isAuth: true,
      keepAlive: false
    }
  },
  {
    path: '/aiot/tunnel/person/recevice-log',
    name: 'log',
    component: () => import('$aiot/views/tunnel/person/recevice/Recevice.vue'),
    meta: {
      name: '接收日志',
      authCode: 'web_pm_tunnel_person_recevice',
      isAuth: true,
      keepAlive: false
    }
  }
]
export default laborRoute
